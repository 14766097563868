html,
body {
    height: 100%;
}

li.dropdown-submenu.open > a {
    color: #777 !important;
}

body > div.content-wrapper {
    background: white;
}

.wrap {
    min-height: 100%;
    height: auto;
    margin: 0 auto -60px;
    padding: 0 0 60px;
}

.wrap > .container {
    padding: 70px 15px 20px;
}

.footer {
    height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.jumbotron {
    text-align: center;
    background-color: transparent;
}

.jumbotron .btn {
    font-size: 21px;
    padding: 14px 24px;
}

.not-set {
    color: #c55;
    font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after, a.desc:after {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    padding-left: 5px;
}

a.asc:after {
    content: /*"\e113"*/ "\e151";
}

a.desc:after {
    content: /*"\e114"*/ "\e152";
}

.sort-numerical a.asc:after {
    content: "\e153";
}

.sort-numerical a.desc:after {
    content: "\e154";
}

.sort-ordinal a.asc:after {
    content: "\e155";
}

.sort-ordinal a.desc:after {
    content: "\e156";
}

.grid-view th {
    white-space: nowrap;
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}

.list-view ul.sorter {
    list-style: none;
}

.list-view ul.sorter li {
    display: inline-block;
}

div.required:not(.force-not-required) label.control-label:after {
    content: " *";
    color: red;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak, [v-cloak], .v-cloak {
    display: none !important;
}

.info-icon {
    background-color: darkgrey;
    border-radius: 2px;
    color: white;
    padding: 1px 4px;
    margin: 0;
}

.info-icon-success {
    background-color: #00a65a;
}

.info-icon-primary {
    background-color: #337ab7;
}

.info-icon-danger {
    background-color: #d73925;
}

.info-icon-gray {
    background-color: #555555;
}

table.table-wrapped th, table.table-wrapped td {
    overflow-wrap: break-word;
}

table.table-wrapped {
    table-layout: fixed;
}

/*table.table tbody tr:hover {*/
    /*background-color: #DDD;*/
/*}*/

table.detail-view {
    table-layout: fixed;
}

table.detail-view th, table.detail-view td {
    overflow-wrap: break-word;
}

@media (min-width: 800px) {
    table.detail-view th {
        width: 30%;
    }

    table.detail-view td {
        width: 70%;
    }
}

@media (max-width: 799px) {
    table.detail-view th {
        width: 40%;
    }

    table.detail-view td {
        width: 60%;
    }
}

a.clac-text-edit:after {
    font-family: "FontAwesome";
}

a.clac-text-edit:hover:after {
    content: '\f040';
    margin-left: 10px;
    color: darkred;
}

.highcharts-container {
    width: 100% !important;
}

.btn-action {
    background-color: #A3A60F;
    border-color: #A3A60F;
    color: white;
}

.btn-action:hover,
.btn-action:focus {
    background-color: #BDD631;
    border-color: #BDD631;
    color: white;
}

.direct-chat-acronym{
    border-radius: 50%;
    line-height: 40px;
    float: left;
    width: 40px;
    height: 40px;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    border: solid 1px #DDD;
    color: #777777;
}

.right .direct-chat-acronym{
    float: right;
}